/* eslint-disable no-console */
const slugify = require('slugify');

const generateProductSlug = ({ productHandle, productType }) => {
  if (!productHandle) {
    console.error('Product handle not supplied to generateProductSlug');
    return null;
  }

  const prefix = process.env.GATSBY_PRODUCT_SLUG_PREFIX;

  let slugRoot = '';
  if (prefix) slugRoot = `${prefix}/`;
  else if (productType)
    slugRoot = `${slugify(productType, { strict: true, lower: true })}/`;

  const slug = `${slugRoot}${productHandle}`;

  return slug;
};

module.exports = generateProductSlug;
